import React, { Component } from 'react';

import { connect } from 'react-redux';

import io from 'socket.io-client';

import Layout from '../../layouts/mainLayout';

import config from '../../config';

class ChatPage extends Component {
  componentDidMount() {
    this.conversationsSocket = io(`${config.backendServer}/conversations`);
    this.trackerSocket = io(`${config.backendServer}/tracker`);
    this.conversationId = window.localStorage.getItem('conversationId');

    this.conversationsSocket.emit('conversations.subscribe', {
      token: this.props.token,
    });
  }

  sendMessage = () => {
    this.conversationsSocket.emit(
      'conversations.messages.new',
      {
        token: this.props.token,
        message: 'This is a test message',
      },
      (err, res) => {
        if (err) {
          console.error('An error occurred attempting to create a message.');
          console.error(err);
        } else {
          console.log(res);
        }
      }
    );
  };

  getConversations = () => {
    this.conversationsSocket.emit(
      'conversations.list',
      { token: this.props.token },
      (err, conversations) => {
        if (err) {
          console.error(err);
        } else {
          console.log(conversations);
        }
      }
    );
  };

  render() {
    return (
      <Layout>
        <div>Chat page</div>
        <button onClick={this.sendMessage}>Send message</button>
        <button onClick={this.getConversations}>Get my conversations</button>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
  };
};

export default connect(mapStateToProps)(ChatPage);
